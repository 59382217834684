.icon-header:before {
  color: white !important;
  font-size: 22px;
  padding-right: 13px;
}

.icon-offer:before {
  color: white !important;
  font-size: 25px;
  padding-right: 25px;
}

.box-description > p:last-child {
  display: block;
  padding-top: 16px;
}
