@font-face {
  font-family: 'vw-head';
  font-weight: 400;
  font-display: swap;
  src:
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwhead-regular.woff2')
      format('woff2'),
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwhead-regular.woff')
      format('woff');
}

@font-face {
  font-family: 'vw-head';
  font-weight: 700;
  font-display: swap;
  src:
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwhead-bold.woff2')
      format('woff2'),
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwhead-bold.woff')
      format('woff');
}

@font-face {
  font-family: 'vw-head';
  font-weight: 200;
  font-display: swap;
  src:
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwhead-light.woff2')
      format('woff2'),
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwhead-light.woff')
      format('woff');
}

@font-face {
  font-family: 'vw-text';
  font-weight: 400;
  font-display: swap;
  src:
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwtext-regular.woff2')
      format('woff2'),
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwtext-regular.woff')
      format('woff');
}

@font-face {
  font-family: 'vw-text';
  font-weight: 700;
  font-display: swap;
  src:
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwtext-bold.woff2')
      format('woff2'),
    url('https://www.volkswagen.pl:443/idhub/etc/clientlibs/vwa-ngw18/ngw18-frontend/clientlibs/statics/fonts/vwtext-bold.woff')
      format('woff');
}

/* sc-component-id: StyledTextComponent-sc-1h30k8b */
.bALzzm {
  margin: -0.11em 0 0.11em 0;
  font-family: vw-head, Helvetica, Arial, sans-serif;
  color: #000000;
  word-break: inherit;
  font-weight: bold;
  font-size: var(--texttypes-headline200-fontsize);
  line-height: var(--texttypes-headline200-lineheight);
  -webkit-letter-spacing: var(--texttypes-headline200-letterspacing);
  -moz-letter-spacing: var(--texttypes-headline200-letterspacing);
  -ms-letter-spacing: var(--texttypes-headline200-letterspacing);
  letter-spacing: var(--texttypes-headline200-letterspacing);
}

.ffIqFt {
  margin: -0.11em 0 0.11em 0;
  font-family: vw-text, Helvetica, Arial, sans-serif;
  color: #000000;
  word-break: inherit;
  font-weight: normal;
  font-size: var(--texttypes-copy150-fontsize);
  line-height: var(--texttypes-copy150-lineheight);
  -webkit-letter-spacing: var(--texttypes-copy150-letterspacing);
  -moz-letter-spacing: var(--texttypes-copy150-letterspacing);
  -ms-letter-spacing: var(--texttypes-copy150-letterspacing);
  letter-spacing: var(--texttypes-copy150-letterspacing);
}

.ehLThv {
  margin: -0.11em 0 0.11em 0;
  font-family: vw-text, Helvetica, Arial, sans-serif;
  color: #000000;
  word-break: inherit;
  font-weight: normal;
  font-size: var(--texttypes-copy100-fontsize);
  line-height: var(--texttypes-copy100-lineheight);
  -webkit-letter-spacing: var(--texttypes-copy100-letterspacing);
  -moz-letter-spacing: var(--texttypes-copy100-letterspacing);
  -ms-letter-spacing: var(--texttypes-copy100-letterspacing);
  letter-spacing: var(--texttypes-copy100-letterspacing);
}

.lkONwG {
  margin: -0.11em 0 0.11em 0;
  font-family: vw-text, Helvetica, Arial, sans-serif;
  color: #6a767d;
  word-break: break-word;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
}

/* sc-component-id: sc-bdVaJa */
.jzBcqt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/* sc-component-id: StyledBaseContainer-sc-1s9pby4 */
.ifjRIm {
  display: grid;
  max-width: 1920px;
  margin: auto;
}

/* sc-component-id: StyledContainer-sc-nhelkh */
.cKOuwF {
  grid-template-columns: repeat(24, 1fr);
  grid-template-areas: 'a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0';
}

/* sc-component-id: StyledChildWrapper-sc-9dbn6y */
.lgYoaz {
  grid-area: a0;
  overflow: hidden;
}

/* sc-component-id: StyledChildWrapper-sc-1ayh1js */
.dJzoMT {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 100%;
  margin-right: 0;
}

.dJzoMT:last-child {
  margin-bottom: 0;
}

.dJzoMT:empty {
  display: none;
}

.hRCTcH {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 100%;
  margin-bottom: 12px;
  margin-right: 0;
}

.hRCTcH:last-child {
  margin-bottom: 0;
}

.hRCTcH:empty {
  display: none;
}

/* sc-component-id: StyledContainer-sc-1eq0g4k */
.kczMzx {
  display: block;
  -webkit-flex-wrap: unset;
  -ms-flex-wrap: unset;
  flex-wrap: unset;
  -webkit-flex-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
  padding: 0;
  padding-right: var(--size-grid002);
  padding-left: var(--size-grid002);
}

.kOXiUD {
  display: block;
  -webkit-flex-wrap: unset;
  -ms-flex-wrap: unset;
  flex-wrap: unset;
  -webkit-flex-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
  padding: 0;
}

/* sc-component-id: StyledLink-sc-1jhtndy */
.bRAihd {
  position: relative;
  color: #001e50;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.bRAihd:hover,
.bRAihd:focus {
  border-bottom-color: #0040c5;
  color: #0040c5;
}

.bRAihd:focus {
  outline: 1px solid #6a767d;
  outline-offset: 4px;
}

.hSRvvw {
  background: none !important;
}

.iSfPEI::before,
.iSfPEI::after {
  background: rgb(0 30 80) !important;
}
.iSfPEI svg {
  fill: rgb(0 30 80) !important;
}

.cOBGYA {
  color: rgb(0 30 80) !important;
}

.bXbjSm svg {
  fill: rgb(0 30 80) !important;
}

.fmVHUW {
  background: #ffffff;
}
/* sc-component-id: sc-global-251237020 */
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

b,
strong {
  font-weight: bold;
}

/* sc-component-id: StyledIntegratorRoot-sc-140bz40 */
.bzGtOa {
  font-family: 'vw-text', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --texttypes-copy100-fontsize: 12px;
  --texttypes-copy100-reducedfontsize: 12px;
  --texttypes-copy100-lineheight: 20px;
  --texttypes-copy100-letterspacing: 0.09px;
  --texttypes-copy150-fontsize: 14px;
  --texttypes-copy150-reducedfontsize: 12px;
  --texttypes-copy150-lineheight: 24px;
  --texttypes-copy150-letterspacing: 0.04px;
  --texttypes-copy200-fontsize: 16px;
  --texttypes-copy200-reducedfontsize: 12px;
  --texttypes-copy200-lineheight: 24px;
  --texttypes-copy200-letterspacing: 0px;
  --texttypes-copy250-fontsize: 20px;
  --texttypes-copy250-reducedfontsize: 12px;
  --texttypes-copy250-lineheight: 32px;
  --texttypes-copy250-letterspacing: -0.09px;
  --texttypes-copy300-fontsize: 24px;
  --texttypes-copy300-reducedfontsize: 12px;
  --texttypes-copy300-lineheight: 36px;
  --texttypes-copy300-letterspacing: -0.17px;
  --texttypes-headline200-fontsize: 16px;
  --texttypes-headline200-reducedfontsize: 12px;
  --texttypes-headline200-lineheight: 20px;
  --texttypes-headline200-letterspacing: 0px;
  --texttypes-headline250-fontsize: 20px;
  --texttypes-headline250-reducedfontsize: 12px;
  --texttypes-headline250-lineheight: 24px;
  --texttypes-headline250-letterspacing: -0.09px;
  --texttypes-headline300-fontsize: 24px;
  --texttypes-headline300-reducedfontsize: 12px;
  --texttypes-headline300-lineheight: 28px;
  --texttypes-headline300-letterspacing: -0.17px;
  --texttypes-headline350-fontsize: 28px;
  --texttypes-headline350-reducedfontsize: 16px;
  --texttypes-headline350-lineheight: 32px;
  --texttypes-headline350-letterspacing: -0.26px;
  --texttypes-headline400-fontsize: 32px;
  --texttypes-headline400-reducedfontsize: 16px;
  --texttypes-headline400-lineheight: 36px;
  --texttypes-headline400-letterspacing: -0.35px;
  --texttypes-headline450-fontsize: 44px;
  --texttypes-headline450-reducedfontsize: 24px;
  --texttypes-headline450-lineheight: 52px;
  --texttypes-headline450-letterspacing: -0.61px;
  --texttypes-headline500-fontsize: 56px;
  --texttypes-headline500-reducedfontsize: 28px;
  --texttypes-headline500-lineheight: 64px;
  --texttypes-headline500-letterspacing: -0.88px;
  --texttypes-headline550-fontsize: 84px;
  --texttypes-headline550-reducedfontsize: 44px;
  --texttypes-headline550-lineheight: 96px;
  --texttypes-headline550-letterspacing: -1.49px;
  --texttypes-headline600-fontsize: 100px;
  --texttypes-headline600-reducedfontsize: 52px;
  --texttypes-headline600-lineheight: 112px;
  --texttypes-headline600-letterspacing: -1.84px;
  --texttypes-label100-fontsize: 12px;
  --texttypes-label100-reducedfontsize: 12px;
  --texttypes-label100-lineheight: 16px;
  --texttypes-label100-letterspacing: 0.09px;
  --texttypes-label150-fontsize: 14px;
  --texttypes-label150-reducedfontsize: 12px;
  --texttypes-label150-lineheight: 16px;
  --texttypes-label150-letterspacing: 0.04px;
  --texttypes-label200-fontsize: 16px;
  --texttypes-label200-reducedfontsize: 12px;
  --texttypes-label200-lineheight: 20px;
  --texttypes-label200-letterspacing: 0px;
  --texttypes-label250-fontsize: 20px;
  --texttypes-label250-reducedfontsize: 12px;
  --texttypes-label250-lineheight: 24px;
  --texttypes-label250-letterspacing: -0.09px;
  --texttypes-label300-fontsize: 24px;
  --texttypes-label300-reducedfontsize: 12px;
  --texttypes-label300-lineheight: 28px;
  --texttypes-label300-letterspacing: -0.17px;
  --textappearances-copy100-fontsize: 12px;
  --textappearances-copy100-reducedfontsize: 12px;
  --textappearances-copy100-lineheight: 20px;
  --textappearances-copy100-letterspacing: 0.09px;
  --textappearances-copy150-fontsize: 14px;
  --textappearances-copy150-reducedfontsize: 12px;
  --textappearances-copy150-lineheight: 24px;
  --textappearances-copy150-letterspacing: 0.04px;
  --textappearances-copy200-fontsize: 16px;
  --textappearances-copy200-reducedfontsize: 12px;
  --textappearances-copy200-lineheight: 24px;
  --textappearances-copy200-letterspacing: 0px;
  --textappearances-copy250-fontsize: 20px;
  --textappearances-copy250-reducedfontsize: 12px;
  --textappearances-copy250-lineheight: 32px;
  --textappearances-copy250-letterspacing: -0.09px;
  --textappearances-copy300-fontsize: 24px;
  --textappearances-copy300-reducedfontsize: 12px;
  --textappearances-copy300-lineheight: 36px;
  --textappearances-copy300-letterspacing: -0.17px;
  --textappearances-headline200-fontsize: 16px;
  --textappearances-headline200-reducedfontsize: 12px;
  --textappearances-headline200-lineheight: 20px;
  --textappearances-headline200-letterspacing: 0px;
  --textappearances-headline250-fontsize: 20px;
  --textappearances-headline250-reducedfontsize: 12px;
  --textappearances-headline250-lineheight: 24px;
  --textappearances-headline250-letterspacing: -0.09px;
  --textappearances-headline300-fontsize: 24px;
  --textappearances-headline300-reducedfontsize: 12px;
  --textappearances-headline300-lineheight: 28px;
  --textappearances-headline300-letterspacing: -0.17px;
  --textappearances-headline350-fontsize: 28px;
  --textappearances-headline350-reducedfontsize: 16px;
  --textappearances-headline350-lineheight: 32px;
  --textappearances-headline350-letterspacing: -0.26px;
  --textappearances-headline400-fontsize: 32px;
  --textappearances-headline400-reducedfontsize: 16px;
  --textappearances-headline400-lineheight: 36px;
  --textappearances-headline400-letterspacing: -0.35px;
  --textappearances-headline450-fontsize: 44px;
  --textappearances-headline450-reducedfontsize: 24px;
  --textappearances-headline450-lineheight: 52px;
  --textappearances-headline450-letterspacing: -0.61px;
  --textappearances-headline500-fontsize: 56px;
  --textappearances-headline500-reducedfontsize: 28px;
  --textappearances-headline500-lineheight: 64px;
  --textappearances-headline500-letterspacing: -0.88px;
  --textappearances-headline550-fontsize: 84px;
  --textappearances-headline550-reducedfontsize: 44px;
  --textappearances-headline550-lineheight: 96px;
  --textappearances-headline550-letterspacing: -1.49px;
  --textappearances-headline600-fontsize: 100px;
  --textappearances-headline600-reducedfontsize: 52px;
  --textappearances-headline600-lineheight: 112px;
  --textappearances-headline600-letterspacing: -1.84px;
  --textappearances-label100-fontsize: 12px;
  --textappearances-label100-reducedfontsize: 12px;
  --textappearances-label100-lineheight: 16px;
  --textappearances-label100-letterspacing: 0.09px;
  --textappearances-label150-fontsize: 14px;
  --textappearances-label150-reducedfontsize: 12px;
  --textappearances-label150-lineheight: 16px;
  --textappearances-label150-letterspacing: 0.04px;
  --textappearances-label200-fontsize: 16px;
  --textappearances-label200-reducedfontsize: 12px;
  --textappearances-label200-lineheight: 20px;
  --textappearances-label200-letterspacing: 0px;
  --textappearances-label250-fontsize: 20px;
  --textappearances-label250-reducedfontsize: 12px;
  --textappearances-label250-lineheight: 24px;
  --textappearances-label250-letterspacing: -0.09px;
  --textappearances-label300-fontsize: 24px;
  --textappearances-label300-reducedfontsize: 12px;
  --textappearances-label300-lineheight: 28px;
  --textappearances-label300-letterspacing: -0.17px;
  --size-grid1: 4.16vw;
  --size-grid2: 8.33vw;
  --size-grid3: 12.5vw;
  --size-grid4: 16.66vw;
  --size-grid5: 20.83vw;
  --size-grid6: 25vw;
  --size-grid7: 29.16vw;
  --size-grid8: 33.33vw;
  --size-grid11: 45.83vw;
  --size-grid15: 62.5vw;
  --size-grid001: 4.16vw;
  --size-grid002: 8.33vw;
  --size-grid003: 12.5vw;
  --size-grid004: 16.66vw;
  --size-grid005: 20.83vw;
  --size-grid006: 25vw;
  --size-grid007: 29.16vw;
  --size-grid008: 33.33vw;
  --size-grid009: 37.5vw;
  --size-grid010: 41.66vw;
  --size-grid011: 45.83vw;
  --size-grid012: 50vw;
  --size-grid013: 54.16vw;
  --size-grid014: 58.33vw;
  --size-grid015: 62.5vw;
  --size-grid016: 66.66vw;
  --size-grid017: 70.83vw;
  --size-grid018: 75vw;
  --size-grid019: 79.16vw;
  --size-grid020: 83.33vw;
  --size-grid021: 87.5vw;
  --size-grid022: 91.66vw;
  --size-grid023: 95.83vw;
  --size-grid024: 100vw;
  --size-dynamic020: 4px;
  --size-dynamic040: 12px;
  --size-dynamic050: 12px;
  --size-dynamic100: 20px;
  --size-dynamic120: 24px;
  --size-dynamic130: 24px;
  --size-dynamic140: 28px;
  --size-dynamic150: 28px;
  --size-dynamic200: 32px;
  --size-dynamic250: 44px;
  --size-dynamic270: 56px;
  --size-dynamic300: 72px;
  --size-dynamic350: 100px;
  --size-dynamic450: 156px;
  --size-dynamic0020: 4px;
  --size-dynamic0040: 12px;
  --size-dynamic0050: 12px;
  --size-dynamic0100: 20px;
  --size-dynamic0120: 24px;
  --size-dynamic0130: 24px;
  --size-dynamic0140: 28px;
  --size-dynamic0150: 28px;
  --size-dynamic0200: 32px;
  --size-dynamic0250: 44px;
  --size-dynamic0270: 56px;
  --size-dynamic0300: 72px;
  --size-dynamic0350: 100px;
  --size-dynamic0450: 156px;
  --size-dynamic-0-1-1: 0;
  --size-dynamic-0-1-2: 0;
  --size-dynamic-0-2-2: 0;
  --size-dynamic-0-4-4: 0;
  --size-dynamic-1-2-2: 4.16vw;
  --size-dynamic-1-4-6: 4.16vw;
  --size-dynamic-2-0-0: 8.33vw;
  --size-dynamic-2-1-1: 8.33vw;
  --size-dynamic-2-1-2: 8.33vw;
  --size-dynamic-2-2-3: 8.33vw;
  --size-dynamic-2-3-3: 8.33vw;
  --size-dynamic-2-3-5: 8.33vw;
  --size-dynamic-2-4-4: 8.33vw;
  --size-dynamic-2-4-6: 8.33vw;
  --size-dynamic-2-5-5: 8.33vw;
  --size-dynamic-2-6-6: 8.33vw;
  --size-dynamic-2-6-7: 8.33vw;
  --size-dynamic-2-7-7: 8.33vw;
  --size-dynamic-2-7-8: 8.33vw;
  --size-dynamic-2-8-8: 8.33vw;
  --size-dynamic-2-10-12: 8.33vw;
  --size-dynamic-4-0-0: 16.66vw;
  --size-dynamic-4-2-2: 16.66vw;
  --size-dynamic-4-3-3: 16.66vw;
  --size-dynamic-22-9-9: 91.66vw;
  --size-dynamic-20-14-8-6: 83.33vw;
  --size-dynamic-20-20-18-14: 83.33vw;
  --size-dynamic-22-12-8-6: 91.66vw;
  --size-dynamic-22-22-20-16: 91.66vw;
}

.bzGtOa * {
  box-sizing: border-box;
}

@media (min-width: 560px) {
  .bzGtOa {
    --textappearances-headline350-fontsize: 32px;
    --textappearances-headline350-reducedfontsize: 16px;
    --textappearances-headline350-lineheight: 36px;
    --textappearances-headline350-letterspacing: -0.35px;
    --textappearances-headline400-fontsize: 48px;
    --textappearances-headline400-reducedfontsize: 24px;
    --textappearances-headline400-lineheight: 56px;
    --textappearances-headline400-letterspacing: -0.7px;
    --textappearances-headline450-fontsize: 60px;
    --textappearances-headline450-reducedfontsize: 32px;
    --textappearances-headline450-lineheight: 68px;
    --textappearances-headline450-letterspacing: -0.96px;
    --textappearances-headline500-fontsize: 72px;
    --textappearances-headline500-reducedfontsize: 36px;
    --textappearances-headline500-lineheight: 80px;
    --textappearances-headline500-letterspacing: -1.22px;
    --textappearances-headline550-fontsize: 96px;
    --textappearances-headline550-reducedfontsize: 48px;
    --textappearances-headline550-lineheight: 108px;
    --textappearances-headline550-letterspacing: -1.75px;
    --textappearances-headline600-fontsize: 116px;
    --textappearances-headline600-reducedfontsize: 60px;
    --textappearances-headline600-lineheight: 128px;
    --textappearances-headline600-letterspacing: -2.19px;
    --size-dynamic0020: 4px;
    --size-dynamic0040: 20px;
    --size-dynamic0050: 20px;
    --size-dynamic0100: 24px;
    --size-dynamic0120: 20px;
    --size-dynamic0130: 40px;
    --size-dynamic0140: 32px;
    --size-dynamic0150: 52px;
    --size-dynamic0200: 56px;
    --size-dynamic0250: 68px;
    --size-dynamic0270: 80px;
    --size-dynamic0300: 96px;
    --size-dynamic0350: 132px;
    --size-dynamic0450: 172px;
  }
}

@media (min-width: 768px) {
  .bzGtOa {
    --texttypes-headline350-fontsize: 32px;
    --texttypes-headline350-reducedfontsize: 16px;
    --texttypes-headline350-lineheight: 36px;
    --texttypes-headline350-letterspacing: -0.35px;
    --texttypes-headline400-fontsize: 48px;
    --texttypes-headline400-reducedfontsize: 24px;
    --texttypes-headline400-lineheight: 56px;
    --texttypes-headline400-letterspacing: -0.7px;
    --texttypes-headline450-fontsize: 60px;
    --texttypes-headline450-reducedfontsize: 32px;
    --texttypes-headline450-lineheight: 68px;
    --texttypes-headline450-letterspacing: -0.96px;
    --texttypes-headline500-fontsize: 72px;
    --texttypes-headline500-reducedfontsize: 36px;
    --texttypes-headline500-lineheight: 80px;
    --texttypes-headline500-letterspacing: -1.22px;
    --texttypes-headline550-fontsize: 96px;
    --texttypes-headline550-reducedfontsize: 48px;
    --texttypes-headline550-lineheight: 108px;
    --texttypes-headline550-letterspacing: -1.75px;
    --texttypes-headline600-fontsize: 116px;
    --texttypes-headline600-reducedfontsize: 60px;
    --texttypes-headline600-lineheight: 128px;
    --texttypes-headline600-letterspacing: -2.19px;
    --size-dynamic020: 4px;
    --size-dynamic040: 20px;
    --size-dynamic050: 20px;
    --size-dynamic100: 24px;
    --size-dynamic120: 20px;
    --size-dynamic130: 40px;
    --size-dynamic140: 32px;
    --size-dynamic150: 52px;
    --size-dynamic200: 56px;
    --size-dynamic250: 68px;
    --size-dynamic270: 80px;
    --size-dynamic300: 96px;
    --size-dynamic350: 132px;
    --size-dynamic450: 172px;
    --size-dynamic-0-1-1: 4.16vw;
    --size-dynamic-0-1-2: 4.16vw;
    --size-dynamic-0-2-2: 8.33vw;
    --size-dynamic-0-4-4: 16.66vw;
    --size-dynamic-1-2-2: 8.33vw;
    --size-dynamic-1-4-6: 16.66vw;
    --size-dynamic-2-0-0: 0;
    --size-dynamic-2-1-1: 4.16vw;
    --size-dynamic-2-1-2: 4.16vw;
    --size-dynamic-2-2-3: 8.33vw;
    --size-dynamic-2-3-3: 12.5vw;
    --size-dynamic-2-3-5: 12.5vw;
    --size-dynamic-2-4-4: 16.66vw;
    --size-dynamic-2-4-6: 16.66vw;
    --size-dynamic-2-5-5: 20.83vw;
    --size-dynamic-2-6-6: 25vw;
    --size-dynamic-2-6-7: 25vw;
    --size-dynamic-2-7-7: 29.16vw;
    --size-dynamic-2-7-8: 29.16vw;
    --size-dynamic-2-8-8: 33.33vw;
    --size-dynamic-2-10-12: 41.66vw;
    --size-dynamic-4-0-0: 0vw;
    --size-dynamic-4-2-2: 8.33vw;
    --size-dynamic-4-3-3: 12.5vw;
    --size-dynamic-22-9-9: 37.5vw;
    --size-dynamic-20-14-8-6: 58.33vw;
    --size-dynamic-20-20-18-14: 83.33vw;
    --size-dynamic-22-12-8-6: 50vw;
    --size-dynamic-22-22-20-16: 91.66vw;
  }
}

@media (min-width: 1440px) {
  .bzGtOa {
    --texttypes-copy100-fontsize: 14px;
    --texttypes-copy100-reducedfontsize: 12px;
    --texttypes-copy100-lineheight: 24px;
    --texttypes-copy100-letterspacing: 0.04px;
    --texttypes-copy150-fontsize: 16px;
    --texttypes-copy150-reducedfontsize: 12px;
    --texttypes-copy150-lineheight: 24px;
    --texttypes-copy150-letterspacing: 0px;
    --texttypes-copy200-fontsize: 20px;
    --texttypes-copy200-reducedfontsize: 12px;
    --texttypes-copy200-lineheight: 32px;
    --texttypes-copy200-letterspacing: -0.09px;
    --texttypes-copy250-fontsize: 24px;
    --texttypes-copy250-reducedfontsize: 12px;
    --texttypes-copy250-lineheight: 36px;
    --texttypes-copy250-letterspacing: -0.17px;
    --texttypes-copy300-fontsize: 32px;
    --texttypes-copy300-reducedfontsize: 16px;
    --texttypes-copy300-lineheight: 48px;
    --texttypes-copy300-letterspacing: -0.35px;
    --texttypes-headline200-fontsize: 20px;
    --texttypes-headline200-reducedfontsize: 12px;
    --texttypes-headline200-lineheight: 24px;
    --texttypes-headline200-letterspacing: -0.09px;
    --texttypes-headline250-fontsize: 24px;
    --texttypes-headline250-reducedfontsize: 12px;
    --texttypes-headline250-lineheight: 28px;
    --texttypes-headline250-letterspacing: -0.17px;
    --texttypes-headline300-fontsize: 32px;
    --texttypes-headline300-reducedfontsize: 16px;
    --texttypes-headline300-lineheight: 36px;
    --texttypes-headline300-letterspacing: -0.35px;
    --texttypes-headline350-fontsize: 48px;
    --texttypes-headline350-reducedfontsize: 24px;
    --texttypes-headline350-lineheight: 56px;
    --texttypes-headline350-letterspacing: -0.7px;
    --texttypes-headline400-fontsize: 60px;
    --texttypes-headline400-reducedfontsize: 32px;
    --texttypes-headline400-lineheight: 68px;
    --texttypes-headline400-letterspacing: -0.96px;
    --texttypes-headline450-fontsize: 76px;
    --texttypes-headline450-reducedfontsize: 40px;
    --texttypes-headline450-lineheight: 84px;
    --texttypes-headline450-letterspacing: -1.31px;
    --texttypes-headline500-fontsize: 96px;
    --texttypes-headline500-reducedfontsize: 48px;
    --texttypes-headline500-lineheight: 108px;
    --texttypes-headline500-letterspacing: -1.75px;
    --texttypes-headline550-fontsize: 120px;
    --texttypes-headline550-reducedfontsize: 60px;
    --texttypes-headline550-lineheight: 132px;
    --texttypes-headline550-letterspacing: -2.27px;
    --texttypes-headline600-fontsize: 148px;
    --texttypes-headline600-reducedfontsize: 76px;
    --texttypes-headline600-lineheight: 164px;
    --texttypes-headline600-letterspacing: -2.89px;
    --texttypes-label100-fontsize: 14px;
    --texttypes-label100-reducedfontsize: 12px;
    --texttypes-label100-lineheight: 16px;
    --texttypes-label100-letterspacing: 0.04px;
    --texttypes-label150-fontsize: 16px;
    --texttypes-label150-reducedfontsize: 12px;
    --texttypes-label150-lineheight: 20px;
    --texttypes-label150-letterspacing: 0px;
    --texttypes-label200-fontsize: 20px;
    --texttypes-label200-reducedfontsize: 12px;
    --texttypes-label200-lineheight: 24px;
    --texttypes-label200-letterspacing: -0.09px;
    --texttypes-label250-fontsize: 24px;
    --texttypes-label250-reducedfontsize: 12px;
    --texttypes-label250-lineheight: 28px;
    --texttypes-label250-letterspacing: -0.17px;
    --texttypes-label300-fontsize: 32px;
    --texttypes-label300-reducedfontsize: 16px;
    --texttypes-label300-lineheight: 36px;
    --texttypes-label300-letterspacing: -0.35px;
    --size-dynamic020: 8px;
    --size-dynamic040: 32px;
    --size-dynamic050: 24px;
    --size-dynamic100: 28px;
    --size-dynamic120: 20px;
    --size-dynamic130: 52px;
    --size-dynamic140: 48px;
    --size-dynamic150: 64px;
    --size-dynamic200: 72px;
    --size-dynamic250: 84px;
    --size-dynamic270: 100px;
    --size-dynamic300: 120px;
    --size-dynamic350: 196px;
    --size-dynamic450: 184px;
    --size-dynamic-0-1-1: 4.16vw;
    --size-dynamic-0-1-2: 8.33vw;
    --size-dynamic-0-2-2: 8.33vw;
    --size-dynamic-0-4-4: 16.66vw;
    --size-dynamic-1-4-6: 25vw;
    --size-dynamic-2-0-0: 0;
    --size-dynamic-2-1-1: 4.16vw;
    --size-dynamic-2-1-2: 8.33vw;
    --size-dynamic-2-2-3: 12.5vw;
    --size-dynamic-2-3-3: 12.5vw;
    --size-dynamic-2-3-5: 20.83vw;
    --size-dynamic-2-4-4: 16.66vw;
    --size-dynamic-2-4-6: 25vw;
    --size-dynamic-2-5-5: 20.83vw;
    --size-dynamic-2-6-6: 25vw;
    --size-dynamic-2-6-7: 29.16vw;
    --size-dynamic-2-7-7: 29.16vw;
    --size-dynamic-2-7-8: 33.33vw;
    --size-dynamic-2-8-8: 33.33vw;
    --size-dynamic-2-10-12: 50vw;
    --size-dynamic-4-0-0: 0vw;
    --size-dynamic-4-2-2: 8.33vw;
    --size-dynamic-4-3-3: 12.5vw;
    --size-dynamic-22-9-9: 37.5vw;
    --size-dynamic-20-14-8-6: 33.33vw;
    --size-dynamic-20-20-18-14: 75vw;
    --size-dynamic-22-12-8-6: 33.33vw;
    --size-dynamic-22-22-20-16: 83.33vw;
  }
}

@media (min-width: 1600px) {
  .bzGtOa {
    --textappearances-copy100-fontsize: 14px;
    --textappearances-copy100-reducedfontsize: 12px;
    --textappearances-copy100-lineheight: 24px;
    --textappearances-copy100-letterspacing: 0.04px;
    --textappearances-copy150-fontsize: 16px;
    --textappearances-copy150-reducedfontsize: 12px;
    --textappearances-copy150-lineheight: 24px;
    --textappearances-copy150-letterspacing: 0px;
    --textappearances-copy200-fontsize: 20px;
    --textappearances-copy200-reducedfontsize: 12px;
    --textappearances-copy200-lineheight: 32px;
    --textappearances-copy200-letterspacing: -0.09px;
    --textappearances-copy250-fontsize: 24px;
    --textappearances-copy250-reducedfontsize: 12px;
    --textappearances-copy250-lineheight: 36px;
    --textappearances-copy250-letterspacing: -0.17px;
    --textappearances-copy300-fontsize: 32px;
    --textappearances-copy300-reducedfontsize: 16px;
    --textappearances-copy300-lineheight: 48px;
    --textappearances-copy300-letterspacing: -0.35px;
    --textappearances-headline200-fontsize: 20px;
    --textappearances-headline200-reducedfontsize: 12px;
    --textappearances-headline200-lineheight: 24px;
    --textappearances-headline200-letterspacing: -0.09px;
    --textappearances-headline250-fontsize: 24px;
    --textappearances-headline250-reducedfontsize: 12px;
    --textappearances-headline250-lineheight: 28px;
    --textappearances-headline250-letterspacing: -0.17px;
    --textappearances-headline300-fontsize: 32px;
    --textappearances-headline300-reducedfontsize: 16px;
    --textappearances-headline300-lineheight: 36px;
    --textappearances-headline300-letterspacing: -0.35px;
    --textappearances-headline350-fontsize: 48px;
    --textappearances-headline350-reducedfontsize: 24px;
    --textappearances-headline350-lineheight: 56px;
    --textappearances-headline350-letterspacing: -0.7px;
    --textappearances-headline400-fontsize: 60px;
    --textappearances-headline400-reducedfontsize: 32px;
    --textappearances-headline400-lineheight: 68px;
    --textappearances-headline400-letterspacing: -0.96px;
    --textappearances-headline450-fontsize: 76px;
    --textappearances-headline450-reducedfontsize: 40px;
    --textappearances-headline450-lineheight: 84px;
    --textappearances-headline450-letterspacing: -1.31px;
    --textappearances-headline500-fontsize: 96px;
    --textappearances-headline500-reducedfontsize: 48px;
    --textappearances-headline500-lineheight: 108px;
    --textappearances-headline500-letterspacing: -1.75px;
    --textappearances-headline550-fontsize: 120px;
    --textappearances-headline550-reducedfontsize: 60px;
    --textappearances-headline550-lineheight: 132px;
    --textappearances-headline550-letterspacing: -2.27px;
    --textappearances-headline600-fontsize: 148px;
    --textappearances-headline600-reducedfontsize: 76px;
    --textappearances-headline600-lineheight: 164px;
    --textappearances-headline600-letterspacing: -2.89px;
    --textappearances-label100-fontsize: 14px;
    --textappearances-label100-reducedfontsize: 12px;
    --textappearances-label100-lineheight: 16px;
    --textappearances-label100-letterspacing: 0.04px;
    --textappearances-label150-fontsize: 16px;
    --textappearances-label150-reducedfontsize: 12px;
    --textappearances-label150-lineheight: 20px;
    --textappearances-label150-letterspacing: 0px;
    --textappearances-label200-fontsize: 20px;
    --textappearances-label200-reducedfontsize: 12px;
    --textappearances-label200-lineheight: 24px;
    --textappearances-label200-letterspacing: -0.09px;
    --textappearances-label250-fontsize: 24px;
    --textappearances-label250-reducedfontsize: 12px;
    --textappearances-label250-lineheight: 28px;
    --textappearances-label250-letterspacing: -0.17px;
    --textappearances-label300-fontsize: 32px;
    --textappearances-label300-reducedfontsize: 16px;
    --textappearances-label300-lineheight: 36px;
    --textappearances-label300-letterspacing: -0.35px;
    --size-dynamic0020: 8px;
    --size-dynamic0040: 32px;
    --size-dynamic0050: 24px;
    --size-dynamic0100: 28px;
    --size-dynamic0120: 20px;
    --size-dynamic0130: 52px;
    --size-dynamic0140: 48px;
    --size-dynamic0150: 64px;
    --size-dynamic0200: 72px;
    --size-dynamic0250: 84px;
    --size-dynamic0270: 100px;
    --size-dynamic0300: 120px;
    --size-dynamic0350: 196px;
    --size-dynamic0450: 184px;
  }
}

@media (min-width: 1920px) {
  .bzGtOa {
    --size-grid1: 80px;
    --size-grid2: 160px;
    --size-grid3: 240px;
    --size-grid4: 320px;
    --size-grid5: 400px;
    --size-grid6: 480px;
    --size-grid7: 560px;
    --size-grid8: 640px;
    --size-grid11: 880px;
    --size-grid15: 1200px;
    --size-dynamic040: 48px;
    --size-dynamic-0-1-1: 80px;
    --size-dynamic-0-1-2: 160px;
    --size-dynamic-0-2-2: 160px;
    --size-dynamic-0-4-4: 320px;
    --size-dynamic-1-2-2: 160px;
    --size-dynamic-1-4-6: 480px;
    --size-dynamic-2-0-0: 0;
    --size-dynamic-2-1-1: 80px;
    --size-dynamic-2-1-2: 160px;
    --size-dynamic-2-2-3: 240px;
    --size-dynamic-2-3-3: 240px;
    --size-dynamic-2-3-5: 400px;
    --size-dynamic-2-4-4: 320px;
    --size-dynamic-2-4-6: 480px;
    --size-dynamic-2-5-5: 400px;
    --size-dynamic-2-6-6: 480px;
    --size-dynamic-2-6-7: 560px;
    --size-dynamic-2-7-7: 560px;
    --size-dynamic-2-7-8: 640px;
    --size-dynamic-2-8-8: 640px;
    --size-dynamic-2-10-12: 960px;
    --size-dynamic-4-0-0: 0px;
    --size-dynamic-4-2-2: 160px;
    --size-dynamic-4-3-3: 240px;
    --size-dynamic-22-9-9: 720px;
    --size-dynamic-20-14-8-6: 480px;
    --size-dynamic-20-20-18-14: 1120px;
    --size-dynamic-22-12-8-6: 480px;
    --size-dynamic-22-22-20-16: 1280px;
  }
}

@media (min-width: 2560px) {
  .bzGtOa {
    --size-grid001: 106.66px;
    --size-grid002: 213.33px;
    --size-grid003: 320px;
    --size-grid004: 426.66px;
    --size-grid005: 533.33px;
    --size-grid006: 640px;
    --size-grid007: 746.66px;
    --size-grid008: 853.33px;
    --size-grid009: 960px;
    --size-grid010: 1066.66px;
    --size-grid011: 1173.33px;
    --size-grid012: 1280px;
    --size-grid013: 1386.66px;
    --size-grid014: 1493.33px;
    --size-grid015: 1600px;
    --size-grid016: 1706.66px;
    --size-grid017: 1813.33px;
    --size-grid018: 1920px;
    --size-grid019: 2026.66px;
    --size-grid020: 2133.33px;
    --size-grid021: 2240px;
    --size-grid022: 2346.66px;
    --size-grid023: 2453.33px;
    --size-grid024: 2560px;
    --size-dynamic0040: 48px;
  }
}

/* sc-component-id: sc-cSHVUG */
.jBhlIh {
  border-top: solid 2px #001e50;
  padding: 44px 0;
}

@media (min-width: 560px) {
  .jBhlIh {
    padding-top: 72px;
    padding-left: var(--size-grid001);
    padding-right: var(--size-grid001);
  }
}

/* sc-component-id: sc-kAzzGY */
@media (min-width: 560px) {
  .lefSlT {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

/* sc-component-id: sc-chPdSV */
.kWiadU {
  border-top: solid 1px #dfe4e8;
  padding: 44px 0 12px;
}

@media (min-width: 560px) {
  .kWiadU {
    padding-top: 72px;
  }
}

/* sc-component-id: sc-kGXeez */
.kYbQgq:not(:last-child) {
  margin-bottom: 12px;
}

@media (min-width: 560px) {
  .kYbQgq:not(:last-child) {
    margin-bottom: 72px;
  }
}

/* sc-component-id: sc-kpOJdX */
.kArKFJ {
  font-size: var(--textappearances-copy200-fontsize);
  font-weight: bold;
  color: #6a767d;
  margin-bottom: 12px;
}

/* sc-component-id: sc-dxgOiQ */
@media (min-width: 560px) {
  .kZmrcN {
    width: var(--size-grid004);
  }

  .kZmrcN:last-child {
    margin-right: calc(-1 * var(--size-grid001));
  }
}

/* sc-component-id: sc-ckVGcZ */
.bLsgMP {
  margin-bottom: 24px;
}

/* sc-component-id: sc-jKJlTe */
.kjlAOl {
  margin: 0 0 44px;
  padding: 0;
  list-style: none;
}

.kjlAOl a {
  display: block;
  padding: 4px 0;
}

/* sc-component-id: sc-eNQAEJ */
@media (min-width: 560px) {
  .FYksS {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

/* sc-component-id: sc-hMqMXs */
.ejiNUn {
  margin-bottom: 44px;
}

@media (min-width: 560px) {
  .ejiNUn {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    margin: 0;
    padding-left: 44px;
    white-space: nowrap;
  }
}

/* sc-component-id: sc-kEYyzF */
.iNVrmh {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* sc-component-id: sc-kkGfuU */
.AlipF {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.AlipF:not(:last-child):after {
  content: '|';
  margin: 0 12px;
}

/* sc-component-id: sc-keyframes-hcsRZJ */
@-webkit-keyframes hcsRZJ {
  0% {
    -webkit-transform: translateY(-200%);
    -ms-transform: translateY(-200%);
    transform: translateY(-200%);
  }
  50% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes hcsRZJ {
  0% {
    -webkit-transform: translateY(-200%);
    -ms-transform: translateY(-200%);
    transform: translateY(-200%);
  }
  50% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.StyledNavigationContentArea-lceQpr.fpxQUr {
  overflow: hidden;
}
.TopBarWrapper-bbZQTs.dxOLRo {
  z-index: 200;
}
.StyledNavigationTopBarV2-dBIDOP.kJsuXq {
  background: white;
  color: #001e50;
}
.kWmfcL::before,
.kWmfcL::after {
  background: #001e50 !important;
}

.StyledPlainLink-fjvMhd.bqxYDJ svg {
  fill: #001e50 !important;
}

.StyledTopBarButton-gaEeUe.idXiVu {
  color: #001e50;
}
.StyledRightItemsWrapperV2-bgEMsi.eCkOjT {
  display: none;
}
