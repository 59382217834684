.icon:before {
  display: block;
  color: #001e50;
  font-size: 25px;
  font-weight: 570;
}

.alice-carousel {
  position: relative;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  position: absolute;
  width: 40px !important;
  top: 0;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.alice-carousel__next-btn {
  right: 0;
}

.alice-carousel__prev-btn > div > p > span::after,
.alice-carousel__next-btn > div > p > span::after {
  color: transparent;
  /* color: red; */
  font-size: 50px;
  z-index: 20;
}

.control-icon-left:before,
.control-icon-right:before {
  position: absolute;
  top: 0;
  color: #00b0f0;
  font-size: 38px;
  font-weight: bold;
  z-index: 10;
  height: 100%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.control-icon-right:before {
  right: 0;
}

@media only screen and (min-width: 768px) {
  .alice-carousel__wrapper {
    width: 70vw !important;
    max-width: 1236px !important;
    margin: auto !important;
  }
}
