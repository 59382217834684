@font-face {
  font-family: 'VW Head';
  src: url('./fonts/VW_Head/VWHeadWeb-Light.woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'VW Head';
  src: url('./fonts/VW_Head/VWHeadWeb-Regular.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'VW Head';
  src: url('./fonts/VW_Head/VWHeadWeb-RegularItalic.woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'VW Head';
  src: url('./fonts/VW_Head/VWHeadWeb-Bold.woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'VW Head';
  src: url('./fonts/VW_Head/VWHeadWeb-BoldItalic.woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'VW Head';
  src: url('./fonts/VW_Head/VWHeadWeb-ExtraBold.woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'VW Text';
  src: url('./fonts/VW_Text/VWTextWeb-Light.woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'VW Text';
  src: url('./fonts/VW_Text/VWTextWeb-Regular.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'VW Text';
  src: url('./fonts/VW_Text/VWTextWeb-RegularItalic.woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'VW Text';
  src: url('./fonts/VW_Text/VWTextWeb-Bold.woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'VW Text';
  src: url('./fonts/VW_Text/VWTextWeb-BoldItalic.woff');
  font-weight: 700;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'VW Text';
}

main a {
  font-family: 'VW Text';
  font-weight: 700;
  line-height: 20px;
  color: #001e50;
  text-underline-offset: 6px;
}

.header-installment-text {
  margin: 16px 0;
}
